import React, { useState, useEffect } from 'react';
import './App.css';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession, deleteUser } from 'aws-amplify/auth';
import { I18n } from 'aws-amplify/utils';
import '@aws-amplify/ui-react/styles.css';
import { 
  Authenticator, 
  View, 
  Image, 
  useTheme,
  Text,
  translations 
} from '@aws-amplify/ui-react';
I18n.putVocabularies(translations);
I18n.setLanguage('en');

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Yourfirm logo"
          src="https://tf-auth0-shared-public-assets.s3.eu-central-1.amazonaws.com/yourfirm/Yourfirm_color_logo_transparent.png"
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; From Youtfirm with love
        </Text>
      </View>
    );
  },
};

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_CLIENT_ID,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_USER_POOL_DOMAIN,
          scopes: ['openid','email','profile','aws.cognito.signin.user.admin'],
          redirectSignIn: ['http://localhost:3000/','https://poc.yourfirm.borsenkooleh.top/'],
          redirectSignOut: ['http://localhost:3000/','https://poc.yourfirm.borsenkooleh.top/'],
          responseType: 'code',
        },
        email: 'true'
      }
    }
  }
});

export default function App() {

  const deleteCurrentAccount = async function handleDeleteUser() {
    try {
      await deleteUser();
    } catch (error) {
      console.log(error);
    }
  }

  const [idToken, setIdToken] = useState('');
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    fetchIdToken();
  }, []);

  useEffect(() => {
    fetchAccessToken();
  }, []);
  
  const fetchIdToken = async () => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken.payload;
      setIdToken(token); 
    } catch (error) {
      console.log('Error fetching ID token', error);
    }
  };

  const fetchAccessToken = async () => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken.payload;
      setAccessToken(token); 
    } catch (error) {
      console.log('Error fetching ID token', error);
    }
  };

  return (
    <Authenticator 
    components={components}
    socialProviders={['google']}
    >
      {({ signOut, user }) =>(
        <div className="App">
          <header className="App-header">
            <img src="https://tf-auth0-shared-public-assets.s3.eu-central-1.amazonaws.com/yourfirm/Yourfirm_white_logo.png"/>
          </header>
          <body>
            <p>
              <h2>Hello {user.username}</h2>
            </p>
            <p>
              <h1>Welcome to Yourfirm B2C job portal!</h1>
            </p>
            <h4>Your ID Token is:</h4>
            <p>
              {JSON.stringify(idToken)}
            </p>
            <h4>Your Access Token is:</h4>
            <p>
              {JSON.stringify(accessToken)}
            </p>
            <h2>Settings</h2>
            <p>
              <button type='submit' onClick={signOut}>Sign Out</button>
            </p>
            <p>
              <button onClick={deleteCurrentAccount}>Delete Current Account</button>
            </p>
          </body>
        </div>
      )}
    </Authenticator>
  );
}
